import { apiTurnos } from "@/core/api/";

export const getAppointmentCancellationReasons = () =>
  apiTurnos.get(`api/public/appointment/cancellationreasons`);

export const getAppointmentValidation = (appointmentId, email) =>
  apiTurnos.get(`api/public/appointment/validation`, { 
    params: {
      appointmentId,
      email,
    }
  });

export const getAppointmentInfo = (payload) =>
  apiTurnos.get(`api/public/appointment/info/${payload}`);

export const cancelAppointment = (payload) =>
  apiTurnos.post(`api/public/appointment/cancel`, payload);
