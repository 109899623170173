<script setup>
import TopNavbar from "./components/TopNavbar.vue";
import FooterComponent from "./components/FooterComponent.vue";
</script>
<template>
  <top-navbar />

  <RouterView />

  <footer-component />
</template>
<script>
export default {
  components: {
    TopNavbar,
    FooterComponent,
  },
};
</script>
<style >
.cursor-pointer {
  cursor: pointer;
}
</style>
