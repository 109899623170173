import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CancelAppointmentView from "../views/CancelAppointmentView.vue";

// eslint-disable-next-line no-undef
const _env = process.env;

const router = createRouter({
  history: createWebHistory(_env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/CancelAppointment",
      name: "CancelAppointment",
      component: CancelAppointmentView,
    },
    { path: "/:pathMatch(.*)*", component: HomeView },
  ],
});

export default router;
