<template>
  <div class="form-group" :class="customClass">
    <label
      v-if="label"
      :for="`input-${label}`"
      :style="{
        color: `${fontColor} !important`,
      }"
    >
      {{ label }}:
    </label>

    <div class="input-group">
      <div v-if="prependIcon" class="input-group-prepend">
        <span class="input-group-text">
          <i class="bi" :class="prependIcon" />
        </span>
      </div>
      <input
        :id="`input-${label}`"
        ref="inputRef"
        v-bind="$attrs"
        autocomplete="none"
        class="form-control"
        :class="{
          prepend: prependIcon,
          clearable: clearable && modelValue,
        }"
        :disabled="disabled"
        :type="type"
        :value="modelValue"
        :style="{
          fontSize: `${fontSize} !important`,
        }"
        @input="onInputChange($event)"
      />
      <div
        v-if="clearable && modelValue"
        class="input-group-append cursor-pointer"
        @click="$emit('update:modelValue', '')"
      >
        <span class="input-group-text">
          <i class="bi bi-x-lg" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";

export default {
  name: "BaseInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    weight: {
      type: Number,
      default: 400,
    },
    size: {
      type: String,
      default: "12pt",
    },
    color: {
      type: String,
      default: "#707070",
    },
    step: {
      type: Number,
      default: 0.01,
    },
    mask: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fontWeight = computed(() => props.weight || 400);
    const fontSize = computed(() => props.size || "14pt");
    const fontColor = computed(() => props.color || "#707070");
    let inputRef = ref(null);

    const onInputChange = (event) => {
      emit("update:modelValue", event.target.value);
    };

    return {
      fontWeight,
      fontSize,
      fontColor,
      inputRef,
      onInputChange,
    };
  },
};
</script>
<style scoped>
.form-group .invalid-feedback {
  position: absolute;
  max-width: 400px;
}
.form-group .input-group .input-group-prepend {
  border-right-width: 0;
}
.form-group .input-group .input-group-prepend .input-group-text {
  background-color: white;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-group .input-group .input-group-prepend .input-group-text i {
  color: #ffa21a;
}
.form-group .input-group .input-group-append {
  border-left-width: 0;
}
.form-group .input-group .input-group-append .input-group-text {
  background-color: white;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-group .input-group .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.form-group .input-group .form-control.prepend {
  border-left: 0;
}
.form-group .input-group .form-control.clearable {
  border-right: 0;
}
.form-group.size-lg .input-group-prepend .input-group-text i::before {
  font-size: 20px;
  font-weight: bold !important;
}
.form-group.size-lg .form-control {
  height: 60px;
  font-size: 18px;
  font-weight: 600;
}
.form-group.size-lg .form-control::placeholder {
  font-size: 18px;
  font-weight: 600;
  color: #272727;
}
.form-group.size-lg .input-group-text {
  height: 60px;
}
</style>
