<template>
  <section class="bg-dark t-b-o">
    <div class="container px-5">
      <h4 class="text-white font-alt mb-4">Preguntas Frecuentes</h4>
      <div class="align-items-center justify-content-center">
        <div id="accordionFQA" class="accordion">
          <div class="accordion-item">
            <h2 id="faq-1" class="accordion-header">
              <button
                aria-controls="collapseFaq-1"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-1"
                data-bs-toggle="collapse"
                type="button"
              >
                1. ¿Cómo navegar?
              </button>
            </h2>
            <div
              id="collapseFaq-1"
              aria-labelledby="faq-1"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                Navegar es muy fácil, podrás ver los servicios para los cuales
                se ofrecen las citas. Una vez seleccionado el servicio te
                presentará servicios adicionales dentro de esa categoría, al
                seleccionarlos podrás ver los documentos requeridos e
                información relacionada. En la parte de abajo podrás oprimir el
                calendario para coordinar una cita.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-2" class="accordion-header">
              <button
                aria-controls="collapseFaq-2"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-2"
                data-bs-toggle="collapse"
                type="button"
              >
                2. ¿Cómo solicitar la cita?
              </button>
            </h2>
            <div
              id="collapseFaq-2"
              aria-labelledby="faq-2"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                Seleccionas el servicio que te interesa, luego podrás ver uno o
                más sub servicios relacionados y procedes a seleccionar el que
                te interesa, luego de leer la información sobre los requisitos
                podrás oprimir el calendario para coordinar la cita.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-3" class="accordion-header">
              <button
                aria-controls="collapseFaq-3"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-3"
                data-bs-toggle="collapse"
                type="button"
              >
                3. ¿Puedo solicitar la oficina que desee?
              </button>
            </h2>
            <div
              id="collapseFaq-3"
              aria-labelledby="faq-3"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                Sí, puedes seleccionar la oficina de tu preferencia, siempre y
                cuando ésta ofrezca los servicios de citas que deseas.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-4" class="accordion-header">
              <button
                aria-controls="collapseFaq-4"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-4"
                data-bs-toggle="collapse"
                type="button"
              >
                4. ¿La cita es por hora o por orden de llegada?
              </button>
            </h2>
            <div
              id="collapseFaq-4"
              aria-labelledby="faq-4"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                Se hará todo lo posible por atenderte lo más cercano posible a
                la hora de tu cita. No aplica el orden de llegada.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="faq-5" class="accordion-header">
              <button
                aria-controls="collapseFaq-5"
                aria-expanded="false"
                class="accordion-button collapsed fw-bold"
                data-bs-target="#collapseFaq-5"
                data-bs-toggle="collapse"
                type="button"
              >
                5. ¿Puedo cancelar una cita?
              </button>
            </h2>
            <div
              id="collapseFaq-5"
              aria-labelledby="faq-5"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionFQA"
            >
              <div class="accordion-body text-justify">
                Sí, para cancelar su cita deberá presionar el enlace de CANCELAR
                CITA en la parte superior de la página. Ingresará su cuenta de
                usuario y el número de cita para proceder a confirmar la
                cancelación.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.text-justify {
  text-align: justify;
}
</style>
