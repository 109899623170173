import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Loading from "vue-loading-overlay";

import "./assets/main.css";

const app = createApp(App);

// eslint-disable-next-line vue/multi-word-component-names
app.component("loading", Loading);

app.use(router);

app.mount("#app");
