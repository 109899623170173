<template>
  <!-- Navigation-->
  <nav id="mainNav" class="navbar navbar-expand-lg navbar-light">
    <div class="container px-2">
      <a class="navbar-brand fw-bold" @click="goHome()"
        >
        <img src="../assets/img/logo.svg" style="height: 3rem"
      /></a>
      <button
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler border-0"
        data-bs-target="#navbarResponsive"
        data-bs-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon border-0" />
      </button>
      <div id="navbarResponsive" class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
          <li class="nav-item">
            <a class="nav-link me-lg-3 fw-bold pointer" @click="goHome()"
              >Inicio</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link me-lg-3 fw-bold pointer"
              @click="goCancelAppointment()"
              >Cancelar Cita</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: 'home'});
    },
    goCancelAppointment() {
      this.$router.push({ name: 'CancelAppointment'})
    },
  },
};
</script>
