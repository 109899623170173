<template>
  <!-- Footer-->
  <footer class="bg-orange text-center py-5">
    <div class="container px-5">
      <div
        class="d-flex flex-sm-row flex-column justify-content-between align-items-center"
      >
        <div class="d-flex">
          <img alt="..." src="../assets/img/logo_w.svg" style="height: 3rem" />
        </div>
        <div
          class="d-flex text-white small justify-content-between align-items-center"
        >
          <div class="mb-2">
            &copy; {{ new Date().getFullYear() }} Copyright by TurnosMediaLLC
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
