import axios from "axios";

// eslint-disable-next-line no-undef
const _env = process.env;

export const apiTurnos = axios.create({
  baseURL: _env.VUE_APP_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 0, // TODO: Make this configurable
});
