<script setup>
import FaqComponent from "../components/home/FaqComponent.vue";
import FilterComponent from "../components/home/FilterComponent.vue";
import EntityComponent from "../components/home/EntityComponent.vue";
</script>

<template>
  <!-- Mashead header-->
  <header class="masthead">
    <div class="container px-5">
      <div class="row gx-5">
        <div class="col-lg-7" :class="isMobile ? 'p-0' : ''">
          <!-- Mashead text and app badges-->
          <div class="mb-5 mb-lg-0 text-center text-lg-start">
            <h1 class="display-5 lh-1 mb-3 title-bold">Más tiempo para ti,</h1>
            <h1 class="1h-1">Agenda tu cita o turnos virtual aqui</h1>
          </div>
        </div>
        <div class="col-lg-5">
          <!-- Masthead device mockup feature-->
          <div class="masthead-device-mockup">
            <img class="img-fluid" src="../assets/img/clock.svg" />
          </div>
        </div>
      </div>
    </div>
  </header>

  <filter-component @selected="selectType" />

  <entity-component v-if="hasSelectedType" :item="selectedType" />

  <faq-component />
</template>

<script>
export default {
  components: {
    FaqComponent,
    FilterComponent,
    EntityComponent,
  },
  data() {
    return {
      currentType: null,
    };
  },
  computed: {
    hasSelectedType() {
      return this.currentType != null;
    },
    selectedType() {
      return this.currentType;
    },
    isMobile() {
      return screen.width <= 760;
    },
  },
  methods: {
    selectType(item) {
      this.currentType = item;
    },
  },
};
</script>
<style scoped>
.masthead-device-mockup {
  max-width: 350px;
  float: right;
}
</style>
