<template>
  <section class="negative-top">
    <div class="container px-5 py-5 bg-white t-b-o">
      <h6 class="font-alt mb-4 fw-bold">Seleccione categoría:</h6>
      <div class="align-items-center justify-content-center mt-5">
        <div class="d-md-flex justify-content-between">
          <div
            v-for="(item, index) in categoryList"
            :key="index"
            class="card d-flex mt-2"
            :class="[item.class, { selected: item.isSelected }]"
            @click="select(item)"
          >
            <div class="card-body text-capitalize fs-5 fw-bold">
              <input type="radio" class="c-orange" :checked="item.isSelected" />
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      categoryList: [
        {
          idType: 3,
          name: "Gobierno",
          class: "me-md-3",
          isSelected: false,
        },
        {
          idType: 108,
          name: "Consorcios",
          class: "",
          isSelected: false,
        },
        {
          idType: 109,
          name: "Municipios",
          class: "ms-md-3",
          isSelected: false,
        },
      ],
    };
  },
  mounted() {
    this.select(this.categoryList[0]);
  },
  methods: {
    select(item) {
      this.unselectAll();
      item.isSelected = true;
      this.$emit("selected", item);
    },
    unselectAll() {
      this.categoryList.map((i) => {
        return (i.isSelected = false);
      });
    },
  },
};
</script>
<style scoped>
/* For Mobile */
@media screen and (max-width: 540px) {
  .negative-top {
    margin-top: -114px;
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 999px) {
  .negative-top {
    margin-top: -140px;
  }
}

@media screen and (min-width: 1000px) {
  .negative-top {
    margin-top: -184px;
  }
}

.card {
  width: 100%;
  padding: 3px;
  cursor: pointer;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}
</style>
