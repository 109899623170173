<template>
  <section>
    <div class="container py-5 bg-white t-b-o">
      <h2 class="font-alt mb-4 fw-bold text-center">Cancelar cita</h2>
      <div
        class="align-items-center justify-content-center mt-5"
        :class="'ps-3 pe-3'"
      >
        <div class="d-md-flex justify-content-between">
          <div class="card-body text-capitalize">
            <div
              v-if="!isValid && !showSuccess"
              class="row justify-content-center"
            >
              <div class="col-lg-6 col-md-6 col-sm-12" :class="'ps-3 pe-3'">
                <v-form ref="formValidator">
                  <label class="label">Correo electrónico: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.email"
                      class="form-control"
                      name="email"
                      placeholder="Correo electrónico"
                      :rules="isRequired"
                      type="email"
                    />
                    <span class="input-group-text">
                      <i class="far fa-calendar-alt" />
                    </span>
                  </div>
                  <error-message class="error" name="email"/>
                  <label class="label">Número de Cita: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.number"
                      class="form-control"
                      name="number"
                      placeholder="Número de cita"
                      :rules="isRequired"
                      type="number"
                    />
                    <span class="input-group-text">
                      <i class="far fa-calendar-alt" />
                    </span>
                  </div>
                  <error-message class="error" name="number" />
                  <div
                    v-if="model.validation"
                    class="alert alert-danger custom-alert"
                  >
                    {{ model.validation }}
                  </div>
                </v-form>
              </div>

              <div class="row justify-content-center text-center mt-4">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <button
                    class="btn btn-continue mb-2"
                    type="button"
                    @click="getValidation()"
                  >
                    Continuar <i class="fas fa-circle-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
            <v-form ref="formInfoValidator">
              <div
                v-if="isValid && !showSuccess"
                class="row justify-content-center"
              >
                <div
                  class="col-lg-6 col-md-6 col-sm-12"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <label class="label">Nombre </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.name"
                      class="form-control"
                      name="name"
                    />
                  </div>

                  <label class="label">Número: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.number"
                      class="form-control"
                      name="number"
                    />
                  </div>

                  <label class="label">Teléfono: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.phone"
                      class="form-control"
                      name="phone"
                    />
                  </div>

                  <label class="label">Fecha y Hora: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.date"
                      class="form-control"
                      name="date"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-6 col-sm-12"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <label class="label">Estatus: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.statusDesc"
                      class="form-control"
                      name="status"
                    />
                  </div>

                  <label class="label">Dirección: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.location"
                      class="form-control"
                      name="location"
                    />
                  </div>

                  <label class="label">Servicio: </label>
                  <div class="input-group mb-3">
                    <v-field
                      v-model="model.serviceDesc"
                      class="form-control"
                      name="service"
                    />
                  </div>

                  <label class="label">Razón de Cancelación: </label>
                  <div class="mb-3 mt-1">
                    <v-select
                      v-model="model.cancelReason"
                      class="custom-vselect"
                      label="name"
                      :options="cancelReasons"
                      placeholder="Seleccione"
                    />
                    <v-field
                      v-show="false"
                      v-model="model.cancelReason"
                      class="form-control"
                      name="reason"
                      :rules="isRequired"
                    />
                    <error-message class="error" name="reason"/>
                  </div>
                </div>
                <div
                  class="col-lg-12 col-md-12 col-sm-12 mt-3"
                  :class="isMobile ? 'ps-3 pe-3' : 'ps-5 pe-5'"
                >
                  <span class="d-flex justify-content-between">
                    <button
                      class="btn btn-light mb-2"
                      type="button"
                      @click="model.isValid = !model.isValid"
                    >
                      <i class="fas fa-circle-arrow-left" /> Regresar
                    </button>
                    <button
                      class="btn btn-continue mb-2"
                      type="button"
                      @click="cancelConfirm()"
                    >
                      Continuar <i class="fas fa-circle-arrow-right" />
                    </button>
                  </span>
                </div>
              </div>
            </v-form>
            <div
              v-if="showSuccess"
              class="row justify-content-center align-items-center text-center"
            >
              <h5>CITA CANCELADA</h5>
              <div>
                <i class="fas fa-calendar" />
              </div>
              <p class="justify-content-center">
                <strong>Su cita ha sido cancelada exitósamente.</strong>
              </p>
              <div>
                <button
                  class="btn btn-continue mb-2"
                  type="button"
                  @click="goHome()"
                >
                  OK <i class="fas fa-circle-arrow-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  getAppointmentValidation,
  getAppointmentCancellationReasons,
  cancelAppointment
} from "@/services/AppointmentService.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  components: {
    vSelect,
    vForm: Form,
    vField: Field,
    ErrorMessage: ErrorMessage,
  },
  data() {
    return {
      model: {
        ClientAppointmentId: 0,
        email: null,
        number: null,
        name: null,
        statusDesc: null,
        phone: null,
        date: null,
        location: null,
        serviceDesc: null,
        cancelReason: null,
        isValid: false,
        validation: "",
      },
      categoryList: [
        {
          idType: 3,
          name: "Gobierno",
          class: "me-md-3",
          isSelected: false,
        },
        {
          idType: 108,
          name: "Consorcios",
          class: "",
          isSelected: false,
        },
        {
          idType: 109,
          name: "Municipios",
          class: "ms-md-3",
          isSelected: false,
        },
      ],
      cancelReasons: [],
      showSuccess: false,
    };
  },
  computed: {
    isValid() {
      return this.model.isValid;
    },
    isMobile() {
      return screen.width <= 760;
    },
  },
  async mounted() {
    await this.getCancellationReasons();
  },
  methods: {
    select(item) {
      this.unselectAll();
      item.isSelected = true;
      this.$emit("selected", item);
    },
    unselectAll() {
      this.categoryList.map((i) => {
        return (i.isSelected = false);
      });
    },
    async getValidation() {
      const isFormValid = await this.$refs.formValidator.validate();
      if (!isFormValid.valid) {
        return;
      }

      await getAppointmentValidation(this.model.number, this.model.email).then(
        async (resp) => {
          this.model = { ...this.model, ...resp.data };
        }
      );
    },
    async getCancellationReasons() {
      await getAppointmentCancellationReasons().then(async (resp) => {
        this.cancelReasons = resp.data;
      });
    },
    isRequired(value) {
      if (!value) {
        return "Este campo es requerido";
      }
      return true;
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
    async cancelConfirm() {
      const formValidator = await this.$refs.formInfoValidator.validate();
      if (!formValidator.valid) {
        return;
      }

      await cancelAppointment({
        clientAppointmentId: this.model.number,
        reason: this.model.cancelReason?.id,
        comment: null
      }).then(() => {
         this.showSuccess = true;
      });
    }
  },
};
</script>
<style scoped>
.label {
  color: var(--unnamed-color-595959);
  text-align: left;
  font: normal normal 600 14px/21px Montserrat;
  letter-spacing: 0px;
  color: #595959;
  opacity: 1;
}
.input-group-text {
  background-color: transparent !important;
  border: none;
  border-bottom: 2px #e9b563 solid;
}

.form-control,
.form-control:focus {
  border: none;
  border-bottom: 2px #e9b563 solid;
  border-radius: 0%;
  padding-left: 0px;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
}

.custom-vselect {
  background-color: transparent !important;
  border: none;
  border-bottom: 2px #e9b563 solid;
}

.card {
  width: 100%;
  padding: 3px;
  cursor: pointer;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}

.btn-coordinate {
  background-color: #e06537;
  color: #fff;
}

.btn-coordinate:hover {
  background-color: #c64819;
  color: #fff;
}

.btn-continue {
  background-color: #e9b563;
  color: #fff;
}

.btn-continue:hover {
  background-color: #c64819;
  color: #fff;
}

.custom-alert {
  font: normal normal 600 14px/21px Montserrat;
}

.error {
  color: red;
  font: normal normal 600 14px/21px Montserrat;
  display: block;
}

.text-center {
  text-align: center;
}

* >>> {
  --vs-controls-color: #e9b563;
  --vs-border-style: none;
  --vs-dropdown-option--active-bg: #e9b563;
}
</style>
