<template>
  <section class="negative-top">
    <div class="container px-5 pt-1 pb-5 bg-white">
      <div class="col-lg-6 mb-2">
        <base-input
          v-model="search"
          type="text"
          custom-class="size-lg"
          prepend-icon="bi-search"
          placeholder="Buscar"
          :clearable="true"
        />
      </div>
      <h6 class="font-alt fw-bold mb-2">Seleccione entidad:</h6>

      <BigLoader v-if="loading" />
      <div v-else class="d-flex align-items-center swiper-container">
        <button
          v-if="swiperRef && !swiperRef.isBeginning"
          class="btn btn-outline-primary action-button prev"
          @click="prepend()"
        >
          <i class="bi bi-chevron-compact-left" />
        </button>
        <button
          v-if="swiperRef && !swiperRef.isEnd"
          class="btn btn-outline-primary action-button next"
          @click="append()"
        >
          <i class="bi bi-chevron-compact-right" />
        </button>
        <swiper
          class="mySwiper"
          :modules="modules"
          :pagination="{
            clickable: true,
          }"
          :slidesPerView="slidesPerView"
          :spaceBetween="spaceBetween"
          @swiper="setSwiperRef"
        >
          <swiper-slide v-for="(option, index) in options" :key="index">
            <div class="row">
              <div :key="i" class="col-md-12 mb-2" v-for="(item, i) in option">
                <div
                  class="card justify-content-center card-company"
                  :class="[item.class, { 'company-selected': item.isSelected }]"
                  @click="onSelect(item)"
                >
                  <small
                    class="mb-2"
                    style="font-size: 12px; border-bottom: 1px solid #e9b563"
                  >
                    {{ item.name }}
                  </small>
                  <img
                    v-if="item.logoFile"
                    class="slider-card-img-top"
                    :src="item.logoFile.publicUrl"
                  />
                  <img
                    v-else
                    class="slider-card-img-top"
                    src="../../assets/img/no-logo.png"
                  />
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-if="currentItem" class="d-flex justify-content-between">
        <div class="card actions-container">
          <div class="card-body">
            <div class="mb-2" style="border-bottom: 1px solid #e9b563">
              {{ currentItem.name }}
            </div>
            <button
              v-if="currentItem.appointmentUrl"
              class="btn btn-coordinate me-2"
              type="button"
              @click="goTo(currentItem.appointmentUrl)"
            >
              <i class="far fa-calendar-alt" /> Coordinar Cita
            </button>
            <button
              v-if="currentItem.virtualUrl"
              class="btn btn-virtual me-2"
              type="button"
              @click="goTo(currentItem.virtualUrl)"
            >
              <i class="fa-solid fa-desktop" /> Ir a Turnos Virtual
            </button>
            <button
              v-if="currentItem.hasPresential"
              class="btn btn-outline-dark"
              disabled
              type="button"
            >
              <i class="fa-solid fa-person-chalkboard" /> Puede asistir de forma
              presencial.
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Feedback Modal-->
  <div
    id="detailModal"
    aria-hidden="true"
    aria-labelledby="detailModalLabel"
    class="modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-orange p-4">
          <h5 id="detailModalLabel" class="modal-title font-alt text-white">
            Empresa seleccionada
          </h5>
          <button
            aria-label="Close"
            class="btn-close btn-close-white"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div v-if="currentItem" class="modal-body border-0 p-4">
          <div class="">
            <div class="card-body">
              <h5 class="card-title">{{ currentItem.name }}</h5>
              <img
                v-if="currentItem.logoFile"
                class="card-img-top"
                :src="currentItem.logoFile.publicUrl"
              />
              <img
                v-if="!currentItem.logoFile"
                class="card-img-top"
                src="../../assets/img/no-logo.png"
              />
            </div>
            <div class="card-body text-center">
              <div class="d-flex justify-content-between">
                <div class="card actions-container">
                  <div class="card-body d-grid">
                    <button
                      v-if="currentItem.appointmentUrl"
                      class="btn btn-coordinate mb-2"
                      type="button"
                      @click="goTo(currentItem.appointmentUrl)"
                    >
                      <i class="far fa-calendar-alt" /> Coordinar Cita
                    </button>
                    <button
                      v-if="currentItem.virtualUrl"
                      class="btn btn-virtual mb-2"
                      type="button"
                      @click="goTo(currentItem.virtualUrl)"
                    >
                      <i class="fa-solid fa-desktop" /> Ir a Turnos Virtual
                    </button>
                    <button
                      v-if="currentItem.hasPresential"
                      class="btn btn-outline-dark"
                      disabled
                      type="button"
                    >
                      <i class="fa-solid fa-person-chalkboard" /> Puede asistir
                      de forma presencial.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItemsByType } from "@/services/CompanyService";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import BaseInput from "../BaseInput.vue";
import BigLoader from "../BigLoader.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    BaseInput,
    BigLoader,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    item: function () {
      this.getItems();
    },
  },
  data() {
    return {
      items: [],
      currentItem: null,
      windowWidth: window.innerWidth,
      modal: null,
      search: "",
      swiperRef: null,
      loading: false,
    };
  },
  computed: {
    slidesPerView() {
      if (this.windowWidth <= 540) return 1;
      if (this.windowWidth <= 767) return 2;

      if (this.windowWidth <= 1200) return 3;

      return 4;
    },
    spaceBetween() {
      // if (this.windowWidth <= 767) return 10;

      // if (this.windowWidth <= 1200) return 20;

      return 10;
    },
    options() {
      let items = this.items;

      if (this.search) {
        const term = this.search
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        items = this.items.filter((x) => {
          return (
            x.name
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(term) !== -1
          );
        });
      }

      return this.groupByN(3, items);
    },
  },
  async mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    await this.getItems();
    // eslint-disable-next-line no-undef
    this.modal = new bootstrap.Modal("#detailModal", {
      keyboard: false,
    });
  },
  methods: {
    setSwiperRef(swiper) {
      this.swiperRef = swiper;
    },
    append() {
      this.swiperRef.slideNext();
    },
    prepend() {
      this.swiperRef.slidePrev();
    },
    onSelect(item) {
      this.unselectAll();
      item.isSelected = true;
      this.currentItem = item;

      this.modal.show();
    },
    unselectAll() {
      this.items.forEach((i) => {
        i.isSelected = false;
      });
    },
    groupByN(n, arr) {
      let result = [];
      for (let i = 0; i < arr.length; i += n) result.push(arr.slice(i, i + n));
      return result;
    },
    async getItems() {
      this.loading = true;
      this.items = [];
      this.currentItem = null;
      await getItemsByType(this.item.idType)
        .then(async (resp) => {
          this.items = resp.data;
        })
        .finally(() => (this.loading = false));
    },
    goTo(url) {
      window.location.href = url;
    },
  },
};
</script>
<style scoped>

.swiper-container {
  position: relative;
}
.card {
  width: 100%;
  padding: 3px;
}

.card.selected {
  background-color: #fdf1db;
  border: solid 1px #e9b563;
}

/* .card:hover {
  margin-top: -10px;
  transition: 0.3s ease-in-out;
} */

.card.company-selected {
  border: solid 2px #e9b563;
}

.c-orange {
  accent-color: #ce8200;
}

.swiper {
  width: 90%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.swiper-pagination {
  margin-top: 20px;
}

::v-deep .swiper-pagination-bullet-active {
  background: #ffa21a;
}

.action-button {
  color: #ffa21a;
  border-color: #ffa21a;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  z-index: 2;
  position: absolute;
}

.action-button > i {
  font-size: 40px;
}

.next {
  right: -20px;
}
.prev {
  left: -20px;
}
.action-button:hover {
  background: #ffa21a;
  border-color: #ffa21a;
  box-shadow: none;
}
.action-button:focus,
.action-button:active {
  background: #ffa21a;
  border-color: #ffa21a;
  color: white;
  box-shadow: none;
}
.action-button:disabled {
  border-color: grey;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.slider-card-img-top {
  max-height: 100px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-container {
  /* height: 230px; */
}

.card-company {
  min-height: 150px;
  min-width: 150px;
  cursor: pointer;
}

.actions-container {
  background-color: #f7f7f7;
  border: none;
}

.btn-coordinate {
  background-color: #e06537;
  color: #fff;
}

.btn-coordinate:hover {
  background-color: #c64819;
  color: #fff;
}

.btn-virtual {
  background-color: #272727;
  color: #fff;
}

.btn-virtual:hover {
  background-color: #555555;
  color: #fff;
}

/* For Mobile */
@media screen and (max-width: 540px) {
  .negative-top {
    margin-top: -114px;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 999px) {
  .negative-top {
    margin-top: -140px;
  }
  .next {
    right: -40px;
  }
  .prev {
    left: -40px;
  }
}

@media screen and (min-width: 1000px) {
  .negative-top {
    margin-top: -184px;
  }
}
</style>
